import React from "react";

const ExpressstackCurriculum = () => {
  return (
    <>
    {/* DESKTOP DEVICE */} 
<div>
  <h2 className="lg:px-16 md:px-10 px-6 text-black text-[20px] md:text-[25px] lg:text-[31px] not-italic font-semibold leading-[normal] capitalize">Curriculum :</h2>
</div>


    <div className="  hidden  md:flex md:flex-row   px-10 md:px-14 lg:px-20 md:gap-x-10 lg:gap-x-20  my-7 md:my-12 ">
    <div className="flex flex-col md:space-y-7 ">
      <div
        className="overflow-x-hidden  first-of-type:mt-0 last-of-type:mb-0"
      >
        <div className="flex flex-col  ">
          <div className="" >
            <h2 className="  flex flex-row  text-[#545353] text-[24px] not-italic font-semibold leading-[normal] capitalize   ">
            Module 1 : Introduction to Express.js
            </h2>
          </div>
        </div>
        <div className="flex flex-col  items-start pt-3">
          <li className="   xl:ml-[70px] py-1 lg:px-10 md:px-5  dark:text-white lg:text-justify text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
          Understanding server-side development
          </li>
          <li className=" xl:ml-[70px] py-1 lg:px-10 md:px-5  dark:text-white lg:text-justify text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
          Introduction to Express.js and its role   </li>
          <li className=" xl:ml-[70px] py-1 lg:px-10 md:px-5  dark:text-white lg:text-justify text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
          Setting up an Express.js development environment
          </li>
        </div>
      </div>
      <div
        className="overflow-x-hidden  first-of-type:mt-0 last-of-type:mb-0"
      >
        <div className="flex flex-col  ">
          <div className="" >
            <h2 className="  flex flex-row  text-[#545353] text-[24px] not-italic font-semibold leading-[normal] capitalize   ">
            Module 3 :Routing in Express.js          </h2>
          </div>
        </div>
        <div className="flex flex-col  items-start pt-3">
          <li className="   xl:ml-[70px] py-1 lg:px-10 md:px-5  dark:text-white lg:text-justify text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
          Defining and configuring routes            </li>
          <li className=" xl:ml-[70px] py-1 lg:px-10 md:px-5  dark:text-white lg:text-justify text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
          Handling dynamic route parameters                </li>
          <li className=" xl:ml-[70px] py-1 lg:px-10 md:px-5  dark:text-white lg:text-justify text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
          Building RESTful APIs with Express.js           </li>
        </div>
      </div>
      <div
        className="overflow-x-hidden  first-of-type:mt-0 last-of-type:mb-0"
      >
        <div className="flex flex-col  ">
          <div className="" >
            <h2 className="  flex flex-row  text-[#545353] text-[24px] not-italic font-semibold leading-[normal] capitalize   ">
            Module 5 : Handling Data and Databases
                        </h2>
          </div>
        </div>
        <div className="flex flex-col  items-start pt-3">
          <li className="   xl:ml-[70px] py-1 lg:px-10 md:px-5  dark:text-white lg:text-justify text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
          Integrating databases (e.g., MongoDB, MySQL)             </li>
          <li className=" xl:ml-[70px] py-1 lg:px-10 md:px-5  dark:text-white lg:text-justify text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
          Building RESTful CRUD operations            </li>
          <li className=" xl:ml-[70px] py-1 lg:px-10 md:px-5  dark:text-white lg:text-justify text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
          Implementing data validation and error handling               </li>
          {/* <li className="xl:ml-[70px] py-1 lg:px-10 md:px-5  dark:text-white lg:text-justify text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
          Database Design and Optimization          </li> */}
        </div>
      </div>
      <div
        className="overflow-x-hidden  first-of-type:mt-0 last-of-type:mb-0"
      >
        <div className="flex flex-col  ">
          <div className="" >
            <h2 className="  flex flex-row  text-[#545353] text-[24px] not-italic font-semibold leading-[normal] capitalize   ">
            Module 7 : Error Handling and Logging
                        </h2>
          </div>
        </div>
        <div className="flex flex-col  items-start pt-3">
          <li className="   xl:ml-[70px] py-1 lg:px-10 md:px-5  dark:text-white lg:text-justify text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
          Managing errors and exceptions          </li>
          <li className=" xl:ml-[70px] py-1 lg:px-10 md:px-5  dark:text-white lg:text-justify text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
          Implementing logging for debugging and monitoring                    </li>
          <li className=" xl:ml-[70px] py-1 lg:px-10 md:px-5  dark:text-white lg:text-justify text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
          Handling errors gracefully               </li>
          {/* <li className="xl:ml-[70px] py-1 lg:px-10 md:px-5  dark:text-white lg:text-justify text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
          Forms and Validation          </li> */}
        </div>
      </div>
      <div
        className="overflow-x-hidden  first-of-type:mt-0 last-of-type:mb-0"
      >
        <div className="flex flex-col  ">
          <div className="" >
            <h2 className="  flex flex-row  text-[#545353] text-[24px] not-italic font-semibold leading-[normal] capitalize   ">
            Module 9 : RESTful API Design and Documentation          </h2>
          </div>
        </div>
        <div className="flex flex-col  items-start pt-3">
          <li className="   xl:ml-[70px] py-1 lg:px-7 pl-5  dark:text-white lg:text-justify text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
          Best practices for RESTful API design           </li>
          <li className="   xl:ml-[70px] py-1 lg:px-7 pl-5  dark:text-white lg:text-justify text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
          Documenting APIs (e.g., Swagger)                    </li>
          <li className="   xl:ml-[70px] py-1 lg:px-7 pl-5  dark:text-white lg:text-justify text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
          Versioning and maintaining APIs            </li>
          {/* <li className="   xl:ml-[70px] py-1 lg:px-7 pl-5  dark:text-white lg:text-justify text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
          Performance Monitoring and Optimization           </li> */}
        </div>

        <div className="flex flex-col py-3 ">
          <div className="" >
            <h2 className="  flex flex-row  text-[#545353] text-[24px] not-italic font-semibold leading-[normal] capitalize   ">
            Module 12 : Version Control and Collaboration          </h2>
          </div>
        </div>

        <div className="flex flex-col  items-start pt-3">
          <li className="   xl:ml-[70px] py-1 lg:px-7 pl-5  dark:text-white lg:text-justify text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
          Using version control systems (e.g., Git)           </li>
          <li className="   xl:ml-[70px] py-1 lg:px-7 pl-5  dark:text-white lg:text-justify text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
          Collaborative development with teams                  </li>
          {/* <li className="   xl:ml-[70px] py-1 lg:px-7 pl-5  dark:text-white lg:text-justify text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
          Versioning and maintaining APIs            </li> */}
          {/* <li className="   xl:ml-[70px] py-1 lg:px-7 pl-5  dark:text-white lg:text-justify text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
          Performance Monitoring and Optimization           </li> */}
        </div>

      </div>
      </div>
      <div className="flex flex-col md:space-y-7">
      <div
        className="overflow-x-hidden  first-of-type:mt-0 last-of-type:mb-0"
      >
        <div className="flex flex-col  ">
          <div className="" >
            <h2 className="  flex flex-row  text-[#545353] text-[24px] not-italic font-semibold leading-[normal] capitalize   ">
            Module 2 :Building Web Servers with Express.js            </h2>
          </div>
        </div>
        <div className="flex flex-col  items-start pt-3">
          <li className="   xl:ml-[70px] py-1 lg:px-10 md:px-5  dark:text-white lg:text-justify text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
          Creating basic web servers with Express.js             </li>
          <li className=" xl:ml-[70px] py-1 lg:px-10 md:px-5  dark:text-white lg:text-justify text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
          Handling HTTP requests and responses                 </li>
          <li className=" xl:ml-[70px] py-1 lg:px-10 md:px-5  dark:text-white lg:text-justify text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
          Implementing middleware for request processing                   </li>
          {/* <li className="xl:ml-[70px] py-1 lg:px-10 md:px-5  dark:text-white lg:text-justify text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
          DOM Manipulation          </li> */}
        </div>
      </div>
      <div
        className="overflow-x-hidden  first-of-type:mt-0 last-of-type:mb-0"
      >
        <div className="flex flex-col  ">
          <div className="" >
            <h2 className="  flex flex-row  text-[#545353] text-[24px] not-italic font-semibold leading-[normal] capitalize   ">
            Module 4 : Working with Templates and Views            </h2>
          </div>
        </div>
        <div className="flex flex-col  items-start pt-3">
          <li className="   xl:ml-[70px] py-1 lg:px-10 md:px-5  dark:text-white lg:text-justify text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
          Using template engines (e.g., Pug, EJS)              </li>
          <li className=" xl:ml-[70px] py-1 lg:px-10 md:px-5  dark:text-white lg:text-justify text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
          Rendering dynamic views and templates                </li>
          <li className=" xl:ml-[70px] py-1 lg:px-10 md:px-5  dark:text-white lg:text-justify text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
          Data binding in templates             </li>
          {/* <li className="xl:ml-[70px] py-1 lg:px-10 md:px-5  dark:text-white lg:text-justify text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
          Working with Databases (SQL or NoSQL)          </li> */}
        </div>
      </div>
      <div
        className="overflow-x-hidden  first-of-type:mt-0 last-of-type:mb-0"
      >
        <div className="flex flex-col  ">
          <div className="" >
            <h2 className="  flex flex-row  text-[#545353] text-[24px] not-italic font-semibold leading-[normal] capitalize   ">
            Module 6 : Authentication and Authorization         </h2>
          </div>
        </div>
        <div className="flex flex-col  items-start pt-3">
          <li className="   xl:ml-[70px] py-1 lg:px-10 md:px-5  dark:text-white lg:text-justify text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
          Implementing user authentication and sessions               </li>
          <li className=" xl:ml-[70px] py-1 lg:px-10 md:px-5  dark:text-white lg:text-justify text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
          Role-based access control
                </li>
          <li className=" xl:ml-[70px] py-1 lg:px-10 md:px-5  dark:text-white lg:text-justify text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
          Securing Express.js applications               </li>
          {/* <li className="xl:ml-[70px] py-1 lg:px-10 md:px-5  dark:text-white lg:text-justify text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
          Integrating with Front-End          </li> */}
        </div>
      </div>
      <div
        className="overflow-x-hidden  first-of-type:mt-0 last-of-type:mb-0"
      >
        <div className="flex flex-col  ">
          <div className="" >
            <h2 className="  flex flex-row  text-[#545353] text-[24px] not-italic font-semibold leading-[normal] capitalize   ">
            Module 8 : File Uploads and Middleware
                        </h2>
          </div>
        </div>
        <div className="flex flex-col  items-start pt-3">
          <li className="   xl:ml-[70px] py-1 lg:px-10 md:px-5  dark:text-white lg:text-justify text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
          Handling file uploads with middleware               </li>
          <li className=" xl:ml-[70px] py-1 lg:px-10 md:px-5  dark:text-white lg:text-justify text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
          Custom middleware development             </li>
          <li className=" xl:ml-[70px] py-1 lg:px-10 md:px-5  dark:text-white lg:text-justify text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
          Managing session and cookie-based authentication                </li>
          {/* <li className="xl:ml-[70px] py-1 lg:px-10 md:px-5  dark:text-white lg:text-justify text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
          Testing Back-End Services          </li> */}
        </div>
      </div>
      <div
        className="overflow-x-hidden  first-of-type:mt-0 last-of-type:mb-0"
      >
        <div className="flex flex-col  ">
          <div className="" >
            <h2 className="  flex flex-row  text-[#545353] text-[24px] not-italic font-semibold leading-[normal] capitalize   ">
            Module 10 : Real-World Projects and Project Development
            </h2>
          </div>
        </div>
        <div className="flex flex-col  items-start pt-3">
          <li className="   xl:ml-[70px] py-1 lg:px-10 md:px-5  dark:text-white lg:text-justify text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
          Students work on Express.js projects </li>

          <li className="   xl:ml-[70px] py-1 lg:px-10 md:px-5  dark:text-white lg:text-justify text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
          Applying Express.js concepts to practical scenarios </li>
        </div>
      </div>
      <div
        className="overflow-x-hidden  first-of-type:mt-0 last-of-type:mb-0"
      >
        <div className="flex flex-col  ">
          <div className="" >
            <h2 className="  flex flex-row  text-[#545353] text-[24px] not-italic font-semibold leading-[normal] capitalize   ">
            Module 11 : Deployment and Scalability          </h2>
          </div>
        </div>
        <div className="flex flex-col  items-start pt-3">
          <li className="   xl:ml-[70px] py-1 lg:px-7 pl-5  dark:text-white lg:text-justify text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
          Preparing Express.js applications for production deployment                    </li>
          <li className="   xl:ml-[70px] py-1 lg:px-7 pl-5  dark:text-white lg:text-justify text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
          Strategies for scaling Express.js applications                             </li>
          <li className="   xl:ml-[70px] py-1 lg:px-7 pl-5  dark:text-white lg:text-justify text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
          Load balancing and performance optimization                     </li>
        </div>
        <div className="flex flex-col py-3 ">
          <div className="" >
            <h2 className="  flex flex-row  text-[#545353] text-[24px] not-italic font-semibold leading-[normal] capitalize   ">
            Module 13 :Career Development and Job Preparation          </h2>
          </div>
        </div>

        <div className="flex flex-col  items-start pt-3">
          <li className="   xl:ml-[70px] py-1 lg:px-7 pl-5  dark:text-white lg:text-justify text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
          Building a portfolio of Express.js projects                  </li>
          <li className="   xl:ml-[70px] py-1 lg:px-7 pl-5  dark:text-white lg:text-justify text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
          Interview preparation and job search strategies                           </li>
          <li className="   xl:ml-[70px] py-1 lg:px-7 pl-5  dark:text-white lg:text-justify text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
          Exploring career opportunities in server-side web development                     </li>
        </div>

      </div>
      </div>
  </div>

    {/* SMALL DEVICES */}
  <div className="flex md:hidden my-7 px-10 xl:px-20 flex-col space-y-5 ">
      <div
        className="overflow-x-hidden  first-of-type:mt-0 last-of-type:mb-0"
      >
        <div className="flex flex-col  ">
          <div className="" >
            <h2 className="  flex flex-row  text-[#545353] text-[20px] not-italic font-semibold leading-[normal] capitalize   ">
            Module 1 : Introduction to Express.js
            </h2>
          </div>
        </div>
        <div className="flex flex-col  items-start pt-3">
          <li className="   xl:ml-[70px] py-1   dark:text-white  text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
          Understanding server-side development
          </li>
          <li className="   dark:text-white  text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
          Introduction to Express.js and its role          </li>
          <li className="   dark:text-white  text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
          Setting up an Express.js development environment
          </li>
        </div>
      </div>
      <div
        className="overflow-x-hidden  first-of-type:mt-0 last-of-type:mb-0"
      >
        <div className="flex flex-col  ">
          <div className="" >
            <h2 className="  flex flex-row  text-[#545353] text-[20px] not-italic font-semibold leading-[normal] capitalize   ">
            Module 2 : Building Web Servers with Express.js            </h2>
          </div>
        </div>
        <div className="flex flex-col  items-start pt-3">
          <li className="     dark:text-white  text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
          Creating basic web servers with Express.js      </li>
          <li className="   dark:text-white  text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
          Handling HTTP requests and responses                   </li>
          <li className="   dark:text-white  text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
          Implementing middleware for request processing                   </li>
          {/* <li className="  dark:text-white  text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
          DOM Manipulation          </li> */}
        </div>
      </div>
      <div
        className="overflow-x-hidden  "
      >
        <div className="flex flex-col  ">
          <div className="" >
            <h2 className="  flex flex-row  text-[#545353] text-[20px] not-italic font-semibold leading-[normal] capitalize   ">
            Module 3 : : Routing in Express.js       </h2>
          </div>
        </div>
        <div className="flex flex-col  items-start pt-3">
          <li className="     dark:text-white  text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
          Defining and configuring routes           </li>
          <li className="   dark:text-white  text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
          Handling dynamic route parameters                 </li>
          <li className="   dark:text-white  text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
          Building RESTful APIs with Express.js          </li>
        </div>
      </div>
      <div
        className="overflow-x-hidden  first-of-type:mt-0 last-of-type:mb-0"
      >
        <div className="flex flex-col  ">
          <div className="" >
            <h2 className="  flex flex-row  text-[#545353] text-[20px] not-italic font-semibold leading-[normal] capitalize   ">
            Module 4 : Working with Templates and Views        </h2>
          </div>
        </div>
        <div className="flex flex-col  items-start pt-3">
          <li className="     dark:text-white  text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
          Using template engines (e.g., Pug, EJS)              </li>
          <li className="   dark:text-white  text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
          Rendering dynamic views and templates               </li>
          <li className="   dark:text-white  text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
          Data binding in templates              </li>
          {/* <li className="  dark:text-white  text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
          Working with Databases (SQL or NoSQL)          </li> */}
        </div>
      </div>
       <div
        className="overflow-x-hidden  first-of-type:mt-0 last-of-type:mb-0"
      >
        <div className="flex flex-col  ">
          <div className="" >
            <h2 className="  flex flex-row  text-[#545353] text-[20px] not-italic font-semibold leading-[normal] capitalize   ">
            Module 5 : Handling Data and Databases
                        </h2>
          </div>
        </div>
        <div className="flex flex-col  items-start pt-3">
          <li className="     dark:text-white  text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
          Integrating databases (e.g., MongoDB, MySQL)            </li>
          <li className="   dark:text-white  text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
          Building RESTful CRUD operations            </li>
          <li className="   dark:text-white  text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
          Implementing data validation and error handling                </li>
          {/* <li className="  dark:text-white  text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
          Database Design and Optimization          </li> */}
        </div>
      </div>
      <div
        className="overflow-x-hidden  first-of-type:mt-0 last-of-type:mb-0"
      >
        <div className="flex flex-col  ">
          <div className="" >
            <h2 className="  flex flex-row  text-[#545353] text-[20px] not-italic font-semibold leading-[normal] capitalize   ">
            Module 6 :  Authentication and Authorization          </h2>
          </div>
        </div>
        <div className="flex flex-col  items-start pt-3">
          <li className="     dark:text-white  text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
          Implementing user authentication and sessions             </li>
          <li className="   dark:text-white  text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
          Role-based access control
             </li>
          <li className="   dark:text-white  text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
          Securing Express.js applications              </li>
          {/* <li className="  dark:text-white  text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
          Integrating with Front-End          </li> */}
        </div>
      </div>
        <div
        className="overflow-x-hidden  first-of-type:mt-0 last-of-type:mb-0"
      >
        <div className="flex flex-col  ">
          <div className="" >
            <h2 className="  flex flex-row  text-[#545353] text-[20px] not-italic font-semibold leading-[normal] capitalize   ">
            Module 7 : Error Handling and Logging
                        </h2>
          </div>
        </div>
        <div className="flex flex-col  items-start pt-3">
          <li className="     dark:text-white  text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
          Managing errors and exceptions           </li>
          <li className="   dark:text-white  text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
          Implementing logging for debugging and monitoring                    </li>
          <li className="   dark:text-white  text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
          Handling errors gracefully              </li>
          {/* <li className="  dark:text-white  text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
          Forms and Validation          </li> */}
        </div>
      </div>
      <div
        className="overflow-x-hidden  first-of-type:mt-0 last-of-type:mb-0"
      >
        <div className="flex flex-col  ">
          <div className="" >
            <h2 className="  flex flex-row  text-[#545353] text-[20px] not-italic font-semibold leading-[normal] capitalize   ">
            Module 8 : File Uploads and Middleware
                        </h2>
          </div>
        </div>
        <div className="flex flex-col  items-start pt-3">
          <li className="     dark:text-white  text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
          Handling file uploads with middleware                 </li>
          <li className="   dark:text-white  text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
          Custom middleware development            </li>
          <li className="   dark:text-white  text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
          Managing session and cookie-based authentication               </li>
          {/* <li className="  dark:text-white  text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
          Testing Back-End Services          </li> */}
        </div>
      </div>
        <div
        className="overflow-x-hidden  first-of-type:mt-0 last-of-type:mb-0"
      >
        <div className="flex flex-col  ">
          <div className="" >
            <h2 className="  flex flex-row  text-[#545353] text-[20px] not-italic font-semibold leading-[normal] capitalize   ">
            Module 9 : RESTful API Design and Documentation         </h2>
          </div>
        </div>
        <div className="flex flex-col  items-start pt-3">
          <li className="     dark:text-white  text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
          Best practices for RESTful API design           </li>
          <li className="     dark:text-white  text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
          Documenting APIs (e.g., Swagger)                   </li>
          <li className="     dark:text-white  text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
          Versioning and maintaining APIs             </li>
          {/* <li className="     dark:text-white  text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
          Performance Monitoring and Optimization           </li> */}
        </div>
      </div>
      <div
        className="overflow-x-hidden  first-of-type:mt-0 last-of-type:mb-0"
      >
        <div className="flex flex-col  ">
          <div className="" >
            <h2 className="  flex flex-row  text-[#545353] text-[20px] not-italic font-semibold leading-[normal] capitalize   ">
            Module 10 : Real-World Projects and Project Development
            </h2>
          </div>
        </div>
        <div className="flex flex-col  items-start pt-3">
          <li className="     dark:text-white  text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
          Students work on Express.js projects        </li>

          <li className="     dark:text-white  text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
          Applying Express.js concepts to practical scenarios        </li>
        </div>
      </div>
      <div
        className="overflow-x-hidden  first-of-type:mt-0 last-of-type:mb-0"
      >
        <div className="flex flex-col  ">
          <div className="" >
            <h2 className="  flex flex-row  text-[#545353] text-[20px] not-italic font-semibold leading-[normal] capitalize   ">
            Module 11 : Deployment and Scalability        </h2>
          </div>
        </div>
        <div className="flex flex-col  items-start pt-3">
          <li className="     dark:text-white  text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
          Preparing Express.js applications for production deployment                    </li>
          <li className="     dark:text-white  text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
          Strategies for scaling Express.js applications                            </li>
          <li className="     dark:text-white  text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
          Load balancing and performance optimization                    </li>
        </div>
      </div>


      <div
        className="overflow-x-hidden  first-of-type:mt-0 last-of-type:mb-0"
      >
        <div className="flex flex-col  ">
          <div className="" >
            <h2 className="  flex flex-row  text-[#545353] text-[20px] not-italic font-semibold leading-[normal] capitalize   ">
            Module 12 : Version Control and Collaboration        </h2>
          </div>
        </div>
        <div className="flex flex-col  items-start pt-3">
          <li className="     dark:text-white  text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
          Using version control systems (e.g., Git)                    </li>
          <li className="     dark:text-white  text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
          Collaborative development with teams                            </li>
          {/* <li className="     dark:text-white  text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
          Load balancing and performance optimization                    </li> */}
        </div>
      </div>

      <div
        className="overflow-x-hidden  first-of-type:mt-0 last-of-type:mb-0"
      >
        <div className="flex flex-col  ">
          <div className="" >
            <h2 className="  flex flex-row  text-[#545353] text-[20px] not-italic font-semibold leading-[normal] capitalize   ">
            Module 13 : Career Development and Job Preparation      </h2>
          </div>
        </div>
        <div className="flex flex-col  items-start pt-3">
          <li className="     dark:text-white  text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
          Building a portfolio of Express.js projects                    </li>
          <li className="     dark:text-white  text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
          Interview preparation and job search strategies                           </li>
          <li className="     dark:text-white  text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
          Exploring career opportunities in server-side web development                    </li>
        </div>
      </div>
  </div>
    
    </>
  );
};

export default ExpressstackCurriculum;

// {/* <div className="flex flex-col px-10 justify-center items-center py-7 ">
// <div className="flex flex-row  py-3">
// <div
//       className="overflow-x-hidden  first-of-type:mt-0 last-of-type:mb-0"
//     >
//       <div className="flex flex-col  ">
//         <div className="" >
//           <h2 className="  flex flex-row  text-[#545353] text-[24px] not-italic font-semibold leading-[normal] capitalize   ">
//           Module 1 : Introduction To Web Development
//           </h2>
//         </div>
//       </div>
//       <div className="flex flex-col  items-start pt-3">
//         <li className="   xl:ml-[70px] py-1 lg:px-10 md:px-5  dark:text-white lg:text-justify text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
//         Understading The Basics Of Web Dvelopment
//         </li>
//         <li className=" xl:ml-[70px] py-1 lg:px-10 md:px-5  dark:text-white lg:text-justify text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
//         Front-End vs. Back-End Development          </li>
//         <li className=" xl:ml-[70px] py-1 lg:px-10 md:px-5  dark:text-white lg:text-justify text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
//         Setting Up Development Environment
//         </li>
//       </div>
//     </div>
//     <div
//       className="overflow-x-hidden  first-of-type:mt-0 last-of-type:mb-0"
//     >
//       <div className="flex flex-col  ">
//         <div className="" >
//           <h2 className="  flex flex-row  text-[#545353] text-[24px] not-italic font-semibold leading-[normal] capitalize   ">
//           Module 2 : HTML, CSS, and JavaScript            </h2>
//         </div>
//       </div>
//       <div className="flex flex-col  items-start pt-3">
//         <li className="   xl:ml-[70px] py-1 lg:px-10 md:px-5  dark:text-white lg:text-justify text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
//         HTML Fundamentals             </li>
//         <li className=" xl:ml-[70px] py-1 lg:px-10 md:px-5  dark:text-white lg:text-justify text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
//         CSS Styling and Layout                    </li>
//         <li className=" xl:ml-[70px] py-1 lg:px-10 md:px-5  dark:text-white lg:text-justify text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
//         JavaScript Basics                    </li>
//         <li className="xl:ml-[70px] py-1 lg:px-10 md:px-5  dark:text-white lg:text-justify text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
//         DOM Manipulation          </li>
//       </div>
//     </div> 
//      </div>
//      <div className="flex flex-row  py-3">
//      <div
//       className="overflow-x-hidden  first-of-type:mt-0 last-of-type:mb-0"
//     >
//       <div className="flex flex-col  ">
//         <div className="" >
//           <h2 className="  flex flex-row  text-[#545353] text-[24px] not-italic font-semibold leading-[normal] capitalize   ">
//           Module 3 : Front-End Development          </h2>
//         </div>
//       </div>
//       <div className="flex flex-col  items-start pt-3">
//         <li className="   xl:ml-[70px] py-1 lg:px-10 md:px-5  dark:text-white lg:text-justify text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
//         Introduction to Front-End Frameworks (e.g., React, Angular, or Vue.js)            </li>
//         <li className=" xl:ml-[70px] py-1 lg:px-10 md:px-5  dark:text-white lg:text-justify text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
//         Building Interactive User Interfaces                  </li>
//         <li className=" xl:ml-[70px] py-1 lg:px-10 md:px-5  dark:text-white lg:text-justify text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
//         State Management in Front-End Applications           </li>
//       </div>
//     </div>
//     <div
//       className="overflow-x-hidden  first-of-type:mt-0 last-of-type:mb-0"
//     >
//       <div className="flex flex-col  ">
//         <div className="" >
//           <h2 className="  flex flex-row  text-[#545353] text-[24px] not-italic font-semibold leading-[normal] capitalize   ">
//           Module 4 : Back-End Development            </h2>
//         </div>
//       </div>
//       <div className="flex flex-col  items-start pt-3">
//         <li className="   xl:ml-[70px] py-1 lg:px-10 md:px-5  dark:text-white lg:text-justify text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
//         Introduction to Back-End Technologies (e.g., Node.js, Python, Ruby, or Java)              </li>
//         <li className=" xl:ml-[70px] py-1 lg:px-10 md:px-5  dark:text-white lg:text-justify text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
//         Building RESTful APIs                 </li>
//         <li className=" xl:ml-[70px] py-1 lg:px-10 md:px-5  dark:text-white lg:text-justify text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
//         Handling Authentication and Authorization               </li>
//         <li className="xl:ml-[70px] py-1 lg:px-10 md:px-5  dark:text-white lg:text-justify text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
//         Working with Databases (SQL or NoSQL)          </li>
//       </div>
//     </div>
//      </div>
//      <div className="flex flex-row  py-3">
//      <div
//       className="overflow-x-hidden  first-of-type:mt-0 last-of-type:mb-0"
//     >
//       <div className="flex flex-col  ">
//         <div className="" >
//           <h2 className="  flex flex-row  text-[#545353] text-[24px] not-italic font-semibold leading-[normal] capitalize   ">
//           Module 5 : Databases
//                       </h2>
//         </div>
//       </div>
//       <div className="flex flex-col  items-start pt-3">
//         <li className="   xl:ml-[70px] py-1 lg:px-10 md:px-5  dark:text-white lg:text-justify text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
//         Introduction to Databases             </li>
//         <li className=" xl:ml-[70px] py-1 lg:px-10 md:px-5  dark:text-white lg:text-justify text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
//         Relational Databases (e.g., MySQL, PostgreSQL)            </li>
//         <li className=" xl:ml-[70px] py-1 lg:px-10 md:px-5  dark:text-white lg:text-justify text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
//         NoSQL Databases (e.g., MongoDB)                </li>
//         <li className="xl:ml-[70px] py-1 lg:px-10 md:px-5  dark:text-white lg:text-justify text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
//         Database Design and Optimization          </li>
//       </div>
//     </div>
//     <div
//       className="overflow-x-hidden  first-of-type:mt-0 last-of-type:mb-0"
//     >
//       <div className="flex flex-col  ">
//         <div className="" >
//           <h2 className="  flex flex-row  text-[#545353] text-[24px] not-italic font-semibold leading-[normal] capitalize   ">
//           Module 6 :  Server-Side Frameworks (e.g., Express.js)           </h2>
//         </div>
//       </div>
//       <div className="flex flex-col  items-start pt-3">
//         <li className="   xl:ml-[70px] py-1 lg:px-10 md:px-5  dark:text-white lg:text-justify text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
//         Setting Up a Back-End Server                 </li>
//         <li className=" xl:ml-[70px] py-1 lg:px-10 md:px-5  dark:text-white lg:text-justify text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
//         Routing and Middleware
//               </li>
//         <li className=" xl:ml-[70px] py-1 lg:px-10 md:px-5  dark:text-white lg:text-justify text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
//         Error Handling and Logging               </li>
//         <li className="xl:ml-[70px] py-1 lg:px-10 md:px-5  dark:text-white lg:text-justify text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
//         Integrating with Front-End          </li>
//       </div>
//     </div> 
//      </div>
//      <div className="flex flex-row  py-3">
//      <div
//       className="overflow-x-hidden  first-of-type:mt-0 last-of-type:mb-0"
//     >
//       <div className="flex flex-col  ">
//         <div className="" >
//           <h2 className="  flex flex-row  text-[#545353] text-[24px] not-italic font-semibold leading-[normal] capitalize   ">
//           Module 7 : Front-End Framework Deep Dive
//                       </h2>
//         </div>
//       </div>
//       <div className="flex flex-col  items-start pt-3">
//         <li className="   xl:ml-[70px] py-1 lg:px-10 md:px-5  dark:text-white lg:text-justify text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
//         Advanced Topics in React/Angular/Vue.js           </li>
//         <li className=" xl:ml-[70px] py-1 lg:px-10 md:px-5  dark:text-white lg:text-justify text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
//         State Management with Redux/NGRX/Vuex                    </li>
//         <li className=" xl:ml-[70px] py-1 lg:px-10 md:px-5  dark:text-white lg:text-justify text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
//         Routing and Navigation               </li>
//         <li className="xl:ml-[70px] py-1 lg:px-10 md:px-5  dark:text-white lg:text-justify text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
//         Forms and Validation          </li>
//       </div>
//     </div>
//     <div
//       className="overflow-x-hidden  first-of-type:mt-0 last-of-type:mb-0"
//     >
//       <div className="flex flex-col  ">
//         <div className="" >
//           <h2 className="  flex flex-row  text-[#545353] text-[24px] not-italic font-semibold leading-[normal] capitalize   ">
//           Module 8 : Advanced Back-End Topics
//                       </h2>
//         </div>
//       </div>
//       <div className="flex flex-col  items-start pt-3">
//         <li className="   xl:ml-[70px] py-1 lg:px-10 md:px-5  dark:text-white lg:text-justify text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
//         Authentication and Security Best Practices                  </li>
//         <li className=" xl:ml-[70px] py-1 lg:px-10 md:px-5  dark:text-white lg:text-justify text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
//         RESTful API Design and Versioning                 </li>
//         <li className=" xl:ml-[70px] py-1 lg:px-10 md:px-5  dark:text-white lg:text-justify text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
//         Handling Asynchronous Operations                 </li>
//         <li className="xl:ml-[70px] py-1 lg:px-10 md:px-5  dark:text-white lg:text-justify text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
//         Testing Back-End Services          </li>
//       </div>
//     </div>
//      </div>
//      <div className="flex flex-row  py-3">
//      <div
//       className="overflow-x-hidden  first-of-type:mt-0 last-of-type:mb-0"
//     >
//       <div className="flex flex-col  ">
//         <div className="" >
//           <h2 className="  flex flex-row  text-[#545353] text-[24px] not-italic font-semibold leading-[normal] capitalize   ">
//           Module 9 : Deployment and DevOps          </h2>
//         </div>
//       </div>
//       <div className="flex flex-col  items-start pt-3">
//         <li className="   xl:ml-[70px] py-1 lg:px-7 pl-5  dark:text-white lg:text-justify text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
//         Preparing Applications for Production            </li>
//         <li className="   xl:ml-[70px] py-1 lg:px-7 pl-5  dark:text-white lg:text-justify text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
//         Deploying to Cloud Services (e.g., AWS, Heroku, or Azure)                    </li>
//         <li className="   xl:ml-[70px] py-1 lg:px-7 pl-5  dark:text-white lg:text-justify text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
//         Continuous Integration and Continuous Deployment (CI/CD)             </li>
//         <li className="   xl:ml-[70px] py-1 lg:px-7 pl-5  dark:text-white lg:text-justify text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
//         Performance Monitoring and Optimization           </li>
//       </div>
//     </div>
//     <div
//       className="overflow-x-hidden  first-of-type:mt-0 last-of-type:mb-0"
//     >
//       <div className="flex flex-col  ">
//         <div className="" >
//           <h2 className="  flex flex-row  text-[#545353] text-[24px] not-italic font-semibold leading-[normal] capitalize   ">
//           Module 10 : Project Work
//           </h2>
//         </div>
//       </div>
//       <div className="flex flex-col  items-start pt-3">
//         <li className="   xl:ml-[70px] py-1 lg:px-10 md:px-5  dark:text-white lg:text-justify text-[#707070] text-[16px] not-italic font-normal leading-[214.8%] capitalize">
//         Students work on a full-stack project of their choice, applying the concepts learned throughout the course.          </li>
//       </div>
//     </div> 
//      </div>
     
// </div> */}