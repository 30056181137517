import React from "react";
import DjangostackCurriculum from "./DjangostackCurriculum";
import DjangostackCarrierstack from "./DjangostackCarrierstack";
import Djangostackmain from "./Djangostackmain";

const Djangostack = (props) => {
  return (
<div className="md:pt-28 pt-16 ">
   <div>
    <Djangostackmain/>
    <div className="py-16">
    <div className="bg-[#FAFAFA] dark:bg-[#000] py-7 px-10 md:px-10 lg:px-20">
        <div className="py-5">
    <div className="py-3">
    <h2 className="text-black dark:text-white text-[18px] md:text-[22px] not-italic font-semibold leading-[normal] capitalize">About Pruthatek's Django Course:</h2>
        </div>
        <div className="py-3">
            <h2 className="text-black dark:text-white text-[16px] text-justify md:text-[18px] not-italic font-medium leading-[182.8%] capitalize">Our Django Course is thoughtfully crafted to equip you with the capabilities and expertise required to thrive in the world of full-stack web development utilizing the Django framework.At Pruthatek, our Django Course is meticulously designed to empower you to excel in web development using the Django framework. Here's what distinguishes our Django Course:</h2>
        </div>
        <div className="py-5">
            <div className="flex flex-col justify-center items-center space-y-5 md:space-y-0 md:flex-row md:justify-between lg:space-x-5">
                <div className="hover:bg-gradient-to-br hover:from-[#F05225] p-[2px] hover:to-[#eea820] transition-all duration-300 hover:bg-clip-border hover:border-transparent">
            <div className="mern flex flex-col  bg-white w-[182px] h-[182px] md:w-[112px] md:h-[132px] lg:w-[182px] lg:h-[182px] ">
                <div className="imgmern flex justify-center items-center pt-6">
                <img src="/img/Union.png" className="w-[25%]" alt=""/>
                </div>
                <h2 className="text-black text-[16px] not-italic px-4 md:px-2 lg:px-4 pt-10 md:pt-3 lg:pt-10 font-medium leading-[182.8%] capitalize">Expertly Crafted</h2>
                <h2 className="bg-gradient-to-r from-[#F05225] px-4 md:px-2 lg:px-4  to-[#EEA820] text-transparent text-[22px] md:text-[18px] lg:text-[22px] bg-clip-text not-italic font-semibold leading-[182.8%] capitalize ">Curriculum</h2>
            </div>
            </div>
            <div className="hover:bg-gradient-to-br hover:from-[#F05225] p-[2px] hover:to-[#eea820] transition-all duration-300 hover:bg-clip-border hover:border-transparent">
            <div className="mern flex flex-col  bg-white w-[182px] h-[182px] md:w-[112px] md:h-[132px] lg:w-[182px] lg:h-[182px] ">
                <div className="imgmern flex justify-center items-center pt-6">
                <img src="/img/Livechat.png" className="w-[25%]" alt=""/>
                </div>
                <h2 className="text-black text-[16px] not-italic px-4 md:px-2 lg:px-4 pt-10 md:pt-3 lg:pt-10 font-medium leading-[182.8%] capitalize">Live Interactive</h2>
                <h2 className="bg-gradient-to-r from-[#F05225] px-4 md:px-2 lg:px-4 to-[#EEA820] text-transparent text-[22px] md:text-[18px] lg:text-[22px]  bg-clip-text not-italic font-semibold leading-[182.8%] capitalize ">Sessions</h2>
            </div>
            </div>
            <div className="hover:bg-gradient-to-br hover:from-[#F05225] p-[2px] hover:to-[#eea820] transition-all duration-300 hover:bg-clip-border hover:border-transparent">
            <div className="mern flex flex-col  bg-white w-[182px] h-[182px] md:w-[112px] md:h-[132px] lg:w-[182px] lg:h-[182px] ">
                <div className="imgmern flex justify-center items-center pt-6">
                <img src="/img/Chat_2.png" className="w-[25%]" alt=""/>
                </div>
                <h2 className="text-black text-[16px] not-italic px-4 md:px-2 lg:px-4 pt-10 md:pt-3 lg:pt-10 font-medium leading-[182.8%] capitalize">Personalised Guided</h2>
                <h2 className="bg-gradient-to-r from-[#F05225] px-4 md:px-2 lg:px-4 to-[#EEA820] text-transparent text-[22px] md:text-[18px] lg:text-[22px] bg-clip-text not-italic font-semibold leading-[182.8%] capitalize ">Learning</h2>
            </div>
            </div>
            <div className="hover:bg-gradient-to-br hover:from-[#F05225] p-[2px] hover:to-[#eea820] transition-all duration-300 hover:bg-clip-border hover:border-transparent">
            <div className="mern flex flex-col  bg-white w-[182px] h-[182px] md:w-[112px] md:h-[132px] lg:w-[182px] lg:h-[182px] ">
                <div className="imgmern flex justify-center items-center pt-6">
                <img src="/img/Icon.png" className="w-[25%]" alt=""/>
                </div>
                <h2 className="text-black text-[16px] not-italic px-4 md:px-2 lg:px-4 pt-10 md:pt-3 lg:pt-10 font-medium leading-[182.8%] capitalize">Hands-On Project</h2>
                <h2 className="bg-gradient-to-r from-[#F05225] px-4 md:px-2 lg:px-4 to-[#EEA820] text-transparent text-[22px] md:text-[18px] lg:text-[22px] bg-clip-text not-italic font-semibold leading-[182.8%] capitalize ">Experience</h2>
            </div>
            </div>
            <div className="hover:bg-gradient-to-br hover:from-[#F05225] p-[2px] hover:to-[#eea820] transition-all duration-300 hover:bg-clip-border hover:border-transparent">
            <div className="mern flex flex-col  bg-white w-[182px] h-[182px] md:w-[112px] md:h-[132px] lg:w-[182px] lg:h-[182px] ">
                <div className="imgmern flex justify-center items-center pt-6">
                <img src="/img/Union2.png" className="w-[25%]" alt=""/>
                </div>
                <h2 className="text-black text-[16px] not-italic px-4 md:px-2 lg:px-4 pt-10 md:pt-3 lg:pt-10 font-medium leading-[182.8%] capitalize">Career</h2>
                <h2 className="bg-gradient-to-r from-[#F05225] px-4 md:px-2 lg:px-4 to-[#EEA820] text-transparent text-[22px] md:text-[18px] lg:text-[22px] bg-clip-text not-italic font-semibold leading-[182.8%] capitalize ">Advancement</h2>
            </div>
            </div>
            <div className="hover:bg-gradient-to-br hover:from-[#F05225] p-[2px] hover:to-[#eea820] transition-all duration-300 hover:bg-clip-border hover:border-transparent">
            <div className="mern flex flex-col  bg-white w-[182px] h-[182px] md:w-[112px] md:h-[132px] lg:w-[182px] lg:h-[182px] ">
                <div className="imgmern flex justify-center items-center pt-6">
                <img src="/img/Usersimg.png" className="w-[25%]" alt=""/>
                </div>
                <h2 className="text-black text-[16px] not-italic px-4 md:px-2 lg:px-4 pt-10 md:pt-3 lg:pt-10 font-medium leading-[182.8%] capitalize">Vibrant Learning</h2>
                <h2 className="bg-gradient-to-r from-[#F05225] px-4 md:px-2 lg:px-4 to-[#EEA820] text-transparent text-[22px] md:text-[18px] lg:text-[22px] bg-clip-text not-italic font-semibold leading-[182.8%] capitalize ">Community</h2>
            </div>
            </div>
            </div>
        </div>
        </div>
    </div>
    </div>
<DjangostackCurriculum/>
<DjangostackCarrierstack/>
   </div>

</div>
    );
};

export default Djangostack;

