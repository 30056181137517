import React from "react";
import MongostackCurriculum from "./MongostackCurriculum";
import MongostackCarrierstack from "./MongostackCarrierstack";
import Mongostackmain from "./Mongostackmain";

const Mongostack = (props) => {
  return (
<div className="md:pt-28 pt-16 ">
   <div>
    <Mongostackmain/>
    <div className="py-16">
    <div className="bg-[#FAFAFA] dark:bg-[#000] py-7 px-10 md:px-10 lg:px-20">
        <div className="py-5">
    <div className="py-3">
    <h2 className="text-black dark:text-white text-[18px] md:text-[22px] not-italic font-semibold leading-[normal] capitalize">About Pruthatek's MongoDB Course:</h2>
        </div>
        <div className="py-3">
            <h2 className="text-black dark:text-white text-[16px] text-justify md:text-[18px] not-italic font-medium leading-[182.8%] capitalize">A MongoDB course curriculum typically covers the fundamentals and advanced concepts of this NoSQL database, enabling you to become proficient in working with MongoDB.At Pruthatek, our MongoDB Course is carefully crafted to equip you with the knowledge and skills necessary to excel in the realm of NoSQL database management. Here's what distinguishes our MongoDB Course:</h2>
        </div>
        <div className="py-5">
            <div className="flex flex-col justify-center items-center space-y-5 md:space-y-0 md:flex-row md:justify-between lg:space-x-5">
                <div className="hover:bg-gradient-to-br hover:from-[#F05225] p-[2px] hover:to-[#eea820] transition-all duration-300 hover:bg-clip-border hover:border-transparent">
            <div className="mern flex flex-col  bg-white w-[182px] h-[182px] md:w-[112px] md:h-[132px] lg:w-[182px] lg:h-[182px] ">
                <div className="imgmern flex justify-center items-center pt-6">
                <img src="/img/Union.png" className="w-[25%]" alt=""/>
                </div>
                <h2 className="text-black text-[16px] not-italic px-4 md:px-2 lg:px-4 pt-10 md:pt-3 lg:pt-10 font-medium leading-[182.8%] capitalize">Comprehensive</h2>
                <h2 className="bg-gradient-to-r from-[#F05225] px-4 md:px-2 lg:px-4  to-[#EEA820] text-transparent text-[22px] md:text-[18px] lg:text-[22px] bg-clip-text not-italic font-semibold leading-[182.8%] capitalize ">Curriculum</h2>
            </div>
            </div>
            <div className="hover:bg-gradient-to-br hover:from-[#F05225] p-[2px] hover:to-[#eea820] transition-all duration-300 hover:bg-clip-border hover:border-transparent">
            <div className="mern flex flex-col  bg-white w-[182px] h-[182px] md:w-[112px] md:h-[132px] lg:w-[182px] lg:h-[182px] ">
                <div className="imgmern flex justify-center items-center pt-6">
                <img src="/img/Livechat.png" className="w-[25%]" alt=""/>
                </div>
                <h2 className="text-black text-[16px] not-italic px-4 md:px-2 lg:px-4 pt-10 md:pt-3 lg:pt-10 font-medium leading-[182.8%] capitalize">Proficiency</h2>
                <h2 className="bg-gradient-to-r from-[#F05225] px-4 md:px-2 lg:px-4 to-[#EEA820] text-transparent text-[22px] md:text-[18px] lg:text-[22px]  bg-clip-text not-italic font-semibold leading-[182.8%] capitalize ">Enhancement</h2>
            </div>
            </div>
            <div className="hover:bg-gradient-to-br hover:from-[#F05225] p-[2px] hover:to-[#eea820] transition-all duration-300 hover:bg-clip-border hover:border-transparent">
            <div className="mern flex flex-col  bg-white w-[182px] h-[182px] md:w-[112px] md:h-[132px] lg:w-[182px] lg:h-[182px] ">
                <div className="imgmern flex justify-center items-center pt-6">
                <img src="/img/Chat_2.png" className="w-[25%]" alt=""/>
                </div>
                <h2 className="text-black text-[16px] not-italic px-4 md:px-2 lg:px-4 pt-10 md:pt-3 lg:pt-10 font-medium leading-[182.8%] capitalize">Network</h2>
                <h2 className="bg-gradient-to-r from-[#F05225] px-4 md:px-2 lg:px-4 to-[#EEA820] text-transparent text-[22px] md:text-[18px] lg:text-[22px] bg-clip-text not-italic font-semibold leading-[182.8%] capitalize ">Building</h2>
            </div>
            </div>
            <div className="hover:bg-gradient-to-br hover:from-[#F05225] p-[2px] hover:to-[#eea820] transition-all duration-300 hover:bg-clip-border hover:border-transparent">
            <div className="mern flex flex-col  bg-white w-[182px] h-[182px] md:w-[112px] md:h-[132px] lg:w-[182px] lg:h-[182px] ">
                <div className="imgmern flex justify-center items-center pt-6">
                <img src="/img/Icon.png" className="w-[25%]" alt=""/>
                </div>
                <h2 className="text-black text-[16px] not-italic px-4 md:px-2 lg:px-4 pt-10 md:pt-3 lg:pt-10 font-medium leading-[182.8%] capitalize">Ongoing Skill</h2>
                <h2 className="bg-gradient-to-r from-[#F05225] px-4 md:px-2 lg:px-4 to-[#EEA820] text-transparent text-[22px] md:text-[18px] lg:text-[22px] bg-clip-text not-italic font-semibold leading-[182.8%] capitalize ">Development</h2>
            </div>
            </div>
            <div className="hover:bg-gradient-to-br hover:from-[#F05225] p-[2px] hover:to-[#eea820] transition-all duration-300 hover:bg-clip-border hover:border-transparent">
            <div className="mern flex flex-col  bg-white w-[182px] h-[182px] md:w-[112px] md:h-[132px] lg:w-[182px] lg:h-[182px] ">
                <div className="imgmern flex justify-center items-center pt-6">
                <img src="/img/Union2.png" className="w-[25%]" alt=""/>
                </div>
                <h2 className="text-black text-[16px] not-italic px-4 md:px-2 lg:px-4 pt-10 md:pt-3 lg:pt-10 font-medium leading-[182.8%] capitalize">Career Path</h2>
                <h2 className="bg-gradient-to-r from-[#F05225] px-4 md:px-2 lg:px-4 to-[#EEA820] text-transparent text-[22px] md:text-[18px] lg:text-[22px] bg-clip-text not-italic font-semibold leading-[182.8%] capitalize ">Guidance</h2>
            </div>
            </div>
            <div className="hover:bg-gradient-to-br hover:from-[#F05225] p-[2px] hover:to-[#eea820] transition-all duration-300 hover:bg-clip-border hover:border-transparent">
            <div className="mern flex flex-col  bg-white w-[182px] h-[182px] md:w-[112px] md:h-[132px] lg:w-[182px] lg:h-[182px] ">
                <div className="imgmern flex justify-center items-center pt-6">
                <img src="/img/Usersimg.png" className="w-[25%]" alt=""/>
                </div>
                <h2 className="text-black text-[16px] not-italic px-4 md:px-2 lg:px-4 pt-10 md:pt-3 lg:pt-10 font-medium leading-[182.8%] capitalize">Thriving</h2>
                <h2 className="bg-gradient-to-r from-[#F05225] px-4 md:px-2 lg:px-4 to-[#EEA820] text-transparent text-[22px] md:text-[18px] lg:text-[22px] bg-clip-text not-italic font-semibold leading-[182.8%] capitalize ">Community</h2>
            </div>
            </div>
            </div>
        </div>
        </div>
    </div>
    </div>
<MongostackCurriculum/>
<div className="py-7 px-10 md:px-20">
            <h2 className="text-black dark:text-white text-[16px] text-justify md:text-[18px] not-italic font-medium leading-[182.8%] capitalize">Please note that the actual curriculum may vary based on the course's duration, depth, and the specific goals of the MongoDB course. This sample curriculum provides an overview of the core topics typically covered in MongoDB training.</h2>
        </div>
<MongostackCarrierstack/>
   </div>

</div>
    );
};

export default Mongostack;

